/**
 * @flow
 */
import React from "react"
import styled from "styled-components"
import { Field, Form as FormikForm, Formik } from "formik"
import * as Yup from "yup"

import media from "../../../styles/media"
import { Button, TextField } from "../../elements"

type Values = {
  code: string,
}

type Actions = {
  setSubmitting: Function,
}

type Props = {
  onSubmit: (values: Values, actions: Actions) => void | Promise<mixed>,
  initialValues?: Values,
}

const validationSchema = Yup.object().shape({
  code: Yup.string().required("Required"),
})

const Container = styled.div`
  display: flex;
  justify-content: center;
  .code-field {
    max-width: 300px;
    input {
      letter-spacing: 2px;
    }
  }
  ${media.tablet`
  .code-field {
    max-width: 300px;
  }
  `}
`

const Form = ({
  onSubmit,
  initialValues = {
    code: "",
  },
}: Props) => {
  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, dirty }) => (
          <FormikForm noValidate>
            <div className="code-field">
              <Field
                autoFocus
                component={TextField}
                id="code"
                name="code"
                placeholder="Code"
                required
                maxLength="6"
              />
            </div>
            <Button
              type="submit"
              label="Verify"
              center
              loading={isSubmitting}
              disabled={isSubmitting || !dirty}
              className="mouse-link"
            />
          </FormikForm>
        )}
      </Formik>
    </Container>
  )
}

export default Form
