/**
 * @flow
 */

import React from "react"

import Footer from "../components/Footer"
import SEO from "../components/SEO"

import VerifyPage from "../components/Auth/VerifyPage"

type Props = {
  location: Object,
}

const Verify = ({ location }: Props) => {
  return (
    <>
      <SEO title="Verify" />
      <VerifyPage location={location} />
      <Footer back />
    </>
  )
}

export default Verify
