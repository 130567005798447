/**
 * @flow
 */

import { Auth, Logger } from "aws-amplify"
import React, { useMemo } from "react"
import { navigate } from "gatsby"
import { useDispatch } from "react-redux"
import styled from "styled-components"

import { notify, setUser } from "../../../state"
import Form from "./form"
import media from "../../../styles/media"
import PageHeader from "../../elements/PageHeader"

import underline from "../../../images/Auth/underline_one.png"

type Props = {
  location: Object,
}

const Container = styled.div`
  &.red {
    background-color: rgba(196, 18, 48, 0.99);
  }
  &.black {
    background-color: rgba(63, 63, 63, 0.99);
  }
  &.p-left {
    padding-left: var(--gutter-s);
  }
  &.p-left-l {
    padding-left: var(--gutter-l);
  }
  &.p-right {
    padding-right: var(--gutter-s);
  }
  &.p-top,
  &.p-bottom {
    padding-bottom: 10vh;
  }
  ${media.tablet`
    &.p-left {
      padding-left: 10vw;
    }
    &.p-left-l {
      padding-left: 10vw;
    }
    &.p-right {
      padding-right: 5vw;
    }
    &.p-top,
    &.p-bottom {
      padding-bottom: 15vh;
    }
  `}
  ${media.laptop`
    &.p-left {
      padding-left: 25vw;
    }
    &.p-left-l {
      padding-left: 25vw;
    }
    &.p-right {
      padding-right: 20vw;
    }
  `}
`

const Intro = styled.div`
  margin-bottom: 4rem;
  position: relative;
  padding-top: 80px;
  h1,
  p {
    color: rgba(255, 255, 255, 0.8);
  }
  h1 {
    font-family: "AvenirLTStdBlack";
    font-size: 2rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-50%) rotate(-2deg);
  }
  p {
    font-family: "butlermedium";
    a {
      text-decoration: underline;
    }
  }
  ${media.laptop`
    h1 {
      font-size: 8rem;
    }
  `}
`

const MetaNav = styled.div`
  text-align: center;
  padding-top: 4rem;
  p {
    font-family: "AvenirLTStdMedium";
    font-size: 0.9rem;
    span {
      display: inline-block;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: calc(100% + 20px);
        height: 100%;
        left: -10px;
        bottom: 0;
        transform: translateY(100%);
        background-image: url(${underline});
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
  }
`

const VerifyPage = ({ location }: Props) => {
  const logger = useMemo(() => new Logger(VerifyPage.name), [])
  const dispatch = useDispatch()

  const handleFormSubmit = async (values, actions) => {
    const { code } = values

    const { email, password } = location.state
    try {
      const result = await Auth.confirmSignUp(email, code)
      logger.info("Verification success.", result)

      if (result) {
        const user = await Auth.signIn(email, password)
        logger.info(user)
        dispatch(setUser({ user: user.attributes, hasUser: true }))
        dispatch(
          notify({
            message: "Verification success.",
            variant: "success",
          })
        )
        setTimeout(() => {
          navigate("/profile", { state: { isWelcome: true } })
        }, 500)
      }
    } catch (err) {
      logger.error(err)
      dispatch(setUser({ hasUser: false }))
      dispatch(
        notify({
          message: err.message,
          variant: "error",
        })
      )
    } finally {
      actions.setSubmitting(false)
    }
  }

  const resendCode = async () => {
    const { email } = location.state
    try {
      const result = await Auth.resendSignUp(email)
      logger.info(result)
      dispatch(
        notify({
          message: "Verification code has been resent",
          variant: "success",
        })
      )
    } catch (err) {
      logger.error(err)
      dispatch(
        notify({
          message: err.message,
          variant: "error",
        })
      )
    }
  }

  return (
    <>
      <Container className="red p-left-l p-bottom">
        <PageHeader />
      </Container>
      <Container className="black p-left p-right p-bottom">
        <Intro>
          <h1>Verify</h1>
          <p>
            Welcome to the Growth Hacker Marketing Masterclass competition.
            We’ve sent a code to your email address. Enter it below to verify
            and continue signing up.
          </p>
        </Intro>
        <Form onSubmit={handleFormSubmit} />
        <MetaNav>
          <p>
            Didn&#39;t get the code?&nbsp;
            <span onClick={resendCode} className="mouse-link">
              Resend
            </span>
          </p>
        </MetaNav>
      </Container>
    </>
  )
}

export default VerifyPage
